import { rtlLanguages, resources } from "translation/i18n";
import logo from "assets/logo.svg";
import landing from "assets/landing.jpg";
// import fullLogo from "assets/fulllogo.png";
import { API_BASE_URL } from "utils/constants";
import { groups, userDefinedEntities } from "./entities";
import { authConfig, authUserDefinedEntities } from "authEntities";
import { customTheme } from "components/Theme";
import { store } from "./index";
import rootReducer from "./reducers";
import { history } from "configureStore";
import dashboardConfig from "dashboard";
import chatConfigs from "chatConfigs";

const storeDispatcher = (type) => {
  store.dispatch({ type });
};

const replaceReducer = (adminPanelReducers) => {
  const nextReducer = rootReducer(history, adminPanelReducers);
  store.replaceReducer(nextReducer);
};

export const adminConfigs = {
  languageResources: resources,
  disableLocalization: true,
  rtlLanguages,
  logo,
  landingImage: landing,
  fullLogo: logo,
  url: API_BASE_URL,
  groups,
  userDefinedEntities,
  authUserDefinedEntities,
  authConfig,
  theme: customTheme,
  privateRouteURL: "/app",
  storeDispatcher,
  dashboardConfig,
  replaceReducer,
  chatConfigs,
  //customRoutes,
};
