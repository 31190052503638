import { takeLatest, call, put } from "redux-saga/effects";
import { request } from "@thewebops/admin-panel";
import actions from "./actions";

function* lockUser(action) {
  const { item } = action.payload;
  const { success, failure } = actions.lockUser;
  try {
    yield call(request, {
      url: `admin/users/${item.id}/lock`,
      method: "patch",
      authenticated: true,
    });

    yield put(success(item));
  } catch (error) {
    yield put(failure());
  }
}

function* unlockUser(action) {
  const { item } = action.payload;
  const { success, failure } = actions.unlockUser;
  try {
    yield call(request, {
      url: `admin/users/${item.id}/unlock`,
      method: "patch",
      authenticated: true,
    });

    yield put(success(item));
  } catch (error) {
    yield put(failure());
  }
}

function* sendMessage(action) {
  const { item } = action.payload;
  const { success, failure } = actions.sendMessage;
  try {
    yield call(request, {
      url: "admin/orientation_messages",
      method: "post",
      authenticated: true,
      params: {
        user_id: item.id,
      },
    });

    yield put(success(item));
  } catch (error) {
    yield put(failure());
  }
}

function* watchEntityLockUser() {
  const { request } = actions.lockUser;
  yield takeLatest(request, lockUser);
}

function* watchEntityUnlockUser() {
  const { request } = actions.unlockUser;
  yield takeLatest(request, unlockUser);
}

function* watchEntitySendMessageUser() {
  const { request } = actions.sendMessage;
  yield takeLatest(request, sendMessage);
}

export default [watchEntityLockUser, watchEntityUnlockUser, watchEntitySendMessageUser];
