import React, { useEffect } from "react";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { entitiesActions, getEntity } from "@thewebops/admin-panel";
import { adminConfigs } from "adminConfigs";

const UserCount = ({ action, field, onChange, state, value, data, errors, ...props }) => {
  const dispatch = useDispatch();
  const { totalEntries, loading } = useSelector(state => getEntity(state, "users"));

  useEffect(() => {
    dispatch(
      entitiesActions["user"].list.request({
        entity: adminConfigs.userDefinedEntities.user,
        active_programs_only: true,
        payment: "paid",
      }),
    );
    return () => dispatch(entitiesActions["user"].list.reset());
  }, [dispatch]);

  return (
    <>
      {action === "edit" && <Typography.Title level={5}>User Count:</Typography.Title>}
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          {value.length}/{totalEntries}
        </div>
      )}
    </>
  );
};

export default UserCount;
