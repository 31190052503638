import { createAction } from "redux-starter-kit";

const actions = {
  lockUser: {
    request: createAction("admin/lockUser/request"),
    success: createAction("admin/lockUser/success"),
    failure: createAction("admin/lockUser/failure"),
  },
  unlockUser: {
    request: createAction("admin/unlockUser/request"),
    success: createAction("admin/unlockUser/success"),
    failure: createAction("admin/unlockUser/failure"),
  },
  sendMessage: {
    request: createAction("admin/send_orientation_messages"),
    success: createAction("admin/send_orientation_messages/success"),
    failure: createAction("admin/send_orientation_messages/failure"),
  }
};

export default actions;
