import { takeLatest, call, put } from "redux-saga/effects";
import { request } from "@thewebops/admin-panel";
import actions from "./actions";

function* lockCoach(action) {
  const { item } = action.payload;
  const { success, failure } = actions.lockCoach;
  try {
    yield call(request, {
      url: `admin/coaches/${item.id}/lock`,
      method: "patch",
      authenticated: true,
    });

    yield put(success(item));
  } catch (error) {
    yield put(failure());
  }
}

function* unlockCoach(action) {
  const { item } = action.payload;
  const { success, failure } = actions.unlockCoach;
  try {
    yield call(request, {
      url: `admin/coaches/${item.id}/unlock`,
      method: "patch",
      authenticated: true,
    });

    yield put(success(item));
  } catch (error) {
    yield put(failure());
  }
}

function* watchEntitylockCoach() {
  const { request } = actions.lockCoach;
  yield takeLatest(request, lockCoach);
}

function* watchEntityUnlockCoach() {
  const { request } = actions.unlockCoach;
  yield takeLatest(request, unlockCoach);
}

export default [watchEntitylockCoach, watchEntityUnlockCoach];
