import { Entity } from "@thewebops/admin-panel";
import UserTraffic from "components/UserTraffic/";

export default new Entity({
  name: "initialAssesment",
  pluralName: "initialAssesments",
  pathName: "initial_assesments",
  endpoint: "/admin/initial_assesments",
  serverName: "initial_assesment",
  serverPluralName: "initial_assesments",
  displayName: "Initial Assesments",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  showSearch: true,
  authorizedRoles: {
    entity: ["super_admin"],
  },
  export: {
    enabled: true,
    extension: "xls",
    fileName: "InitialAssesments",
    authorizedRoles: ["super_admin"],
  },
  filters: [
    {
      label: "Program",
      name: "program_id",
      type: "select",
      selectConfig: {
        defaultValue: "first",
        optionsType: "dynamic",
        optionsEntity: {
          name: "program",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
  fields: [
    {
      identifier: "last_active_at",
      hideInCreate: true,
      hideInEdit: true,
      label: "Activity",
      accessor: (dataObject) => (dataObject.user ? dataObject.user.last_active_at : null),
      inList: true,
      type: "string",
      component: UserTraffic,
    },
    {
      identifier: "user_id",
      label: "Username",
      accessor: (dataObject) => (dataObject.user ? dataObject.user.name : null),
      inList: true,
      usedToShow: true,
      type: "string",
      required: true,
    },
    {
      identifier: "user_email",
      label: "User Email",
      accessor: (dataObject) => (dataObject.user ? dataObject.user.email : null),
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "mobile_number",
      label: "Mobile Number",
      accessor: "mobile_number",

      type: "string",
      required: true,
    },
    {
      identifier: "gender",
      label: "Gender",
      accessor: "gender",

      type: "string",
      required: true,
    },
    {
      identifier: "date_of_birth",
      label: "Date of birth",
      accessor: "date_of_birth",

      type: "string",
      required: true,
    },
    {
      identifier: "full_address",
      label: "Full address",
      accessor: "full_address",

      type: "string",
      required: true,
    },
    {
      identifier: "marital_status",
      label: "Marital status",
      accessor: "marital_status",

      type: "string",
      required: true,
    },
    {
      identifier: "has_children",
      label: "Has children",
      accessor: "has_children",

      type: "string",
      required: true,
    },
    {
      identifier: "children_age",
      label: "Children age",
      accessor: "children_age",

      type: "string",
      required: true,
    },
    {
      identifier: "breast_feed",
      label: "Breast feed",
      accessor: "breast_feed",

      type: "string",
      required: true,
    },
    {
      identifier: "weight",
      label: "Weight",
      accessor: "weight",

      type: "string",
      required: true,
    },
    {
      identifier: "height",
      label: "Height",
      accessor: "height",

      type: "string",
      required: true,
    },
    {
      identifier: "body_fat_percentage",
      label: "Body fat percentage",
      accessor: "body_fat_percentage",

      type: "string",
      required: false,
    },
    {
      identifier: "health_conditions",
      label: "Health conditions",
      accessor: "health_conditions",

      type: "string",
      required: true,
    },
    {
      identifier: "health_medications",
      label: "Health medications",
      accessor: "health_medications",

      type: "string",
      required: true,
    },
    {
      identifier: "health_inquiries",
      label: "Injuries",
      accessor: "health_inquiries",
      type: "string",
      required: true,
    },
    {
      identifier: "health_bone_problem",
      label: "Health bone problem",
      accessor: "health_bone_problem",

      type: "string",
      required: true,
    },
    {
      identifier: "health_doctor_recommendation",
      label: "health doctor recommendation",
      accessor: "health_doctor_recommendation",

      type: "string",
      required: true,
    },
    {
      identifier: "work_do_for_living",
      label: "Work do for living",
      accessor: "work_do_for_living",

      type: "string",
      required: true,
    },
    {
      identifier: "has_shift_work",
      label: "Has shift work",
      accessor: "has_shift_work",

      type: "string",
      required: true,
    },
    {
      identifier: "work_schedule",
      label: "Work schedule",
      accessor: "work_schedule",

      type: "string",
      required: true,
    },
    {
      identifier: "has_sleep_difficulty",
      label: "Has sleep difficulty",
      accessor: "has_sleep_difficulty",

      type: "string",
      required: true,
    },
    {
      identifier: "sleeping_hours",
      label: "Sleeping hours",
      accessor: "sleeping_hours",

      type: "string",
      required: true,
    },
    {
      identifier: "fast_food_meals_no",
      label: "Fast food meals no",
      accessor: "fast_food_meals_no",

      type: "string",
      required: true,
    },
    {
      identifier: "who_makes_food",
      label: "Who makes food",
      accessor: "who_makes_food",

      type: "string",
      required: true,
    },
    {
      identifier: "work_meals",
      label: "Work meals",
      accessor: "work_meals",

      type: "string",
      required: true,
    },
    {
      identifier: "food_allergies",
      label: "Food allergies",
      accessor: "food_allergies",

      type: "string",
      required: true,
    },
    {
      identifier: "food_sensitive",
      label: "food_sensitive",
      accessor: "food_sensitive",

      type: "string",
      required: true,
    },
    {
      identifier: "meals_struggle",
      label: "Meals struggle",
      accessor: "meals_struggle",

      type: "string",
      required: true,
    },
    {
      identifier: "meals_struggle_explaination",
      label: "Meals struggle explaination",
      accessor: "meals_struggle_explaination",

      type: "string",
      required: true,
    },
    {
      identifier: "meal_details_last_24_hours",
      label: "Meal details last 24 hours",
      accessor: "meal_details_last_24_hours",

      type: "string",
      required: true,
    },
    {
      identifier: "groceries_from",
      label: "Groceries from",
      accessor: "groceries_from",

      type: "string",
      required: true,
    },
    {
      identifier: "diet_attempts",
      label: "Diet attempts",
      accessor: "diet_attempts",

      type: "string",
      required: true,
    },
    {
      identifier: "drink_alcohol",
      label: "Drink alcohol",
      accessor: "drink_alcohol",

      type: "string",
      required: true,
    },
    {
      identifier: "drinks_no",
      label: "Drinks no",
      accessor: "drinks_no",

      type: "string",
      required: true,
    },
    {
      identifier: "workout_no",
      label: "Workout no",
      accessor: "workout_no",

      type: "string",
      required: true,
    },
    {
      identifier: "exercise_type",
      label: "Exercise type",
      accessor: "exercise_type",

      type: "string",
      required: true,
    },
    {
      identifier: "gym_facility",
      label: "Gym facility",
      accessor: "gym_facility",

      type: "string",
      required: true,
    },
    {
      identifier: "second_priority_goal",
      label: "Second priority goal",
      accessor: "second_priority_goal",

      type: "string",
      required: true,
    },
    {
      identifier: "goal_timeline",
      label: "Goal timeline",
      accessor: "goal_timeline",

      type: "string",
      required: true,
    },
    {
      identifier: "important_progress",
      label: "Important progress",
      accessor: "important_progress",

      type: "string",
      required: true,
    },
    {
      identifier: "important_progress_explaination",
      label: "Important progress explaination",
      accessor: "important_progress_explaination",

      type: "string",
      required: true,
    },
    {
      identifier: "hear_about_us",
      label: "Hear about us",
      accessor: "hear_about_us",

      type: "string",
      required: true,
    },
    {
      identifier: "other_information",
      label: "Other information",
      accessor: "other_information",

      type: "string",
      required: true,
    },
    {
      identifier: "most_frequent_complains",
      label: "Most frequent complains",
      accessor: "most_frequent_complains",

      type: "string",
      required: true,
    },
    {
      identifier: "travel_plans",
      label: "Travel plans",
      accessor: "travel_plans",
      type: "string",
      required: true,
    },
    {
      identifier: "travel",
      label: "Travel",
      accessor: "travel",
      type: "string",
      required: true,
    },
  ],
});
