import { configureStore } from "redux-starter-kit";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router/immutable";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers";
import rootSaga from "./sagas";

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

// Modified version of redux-starter-kit `getDefaultMiddleware` function without `thunk` middleware
function getDefaultMiddleware() {
  let middlewareArray = [];

  // if (process.env.NODE_ENV !== "production") {
  //   const createImmutableStateInvariantMiddleware = require("redux-immutable-state-invariant").default;
  //   middlewareArray = [createImmutableStateInvariantMiddleware(), createSerializableStateInvariantMiddleware()];
  // }

  return middlewareArray;
}

export const reducer = rootReducer(history);

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer,
    middleware: [sagaMiddleware, routerMiddleware(history), ...getDefaultMiddleware()],
    preloadedState,
  });

  sagaMiddleware.run(rootSaga);

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  }

  return store;
}
