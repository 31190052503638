import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "inquiry",
  pluralName: "inquiries",
  pathName: "inquiries",
  endpoint: "/admin/inquiries",
  serverName: "inquiry",
  serverPluralName: "inquiries",
  displayName: "Inquiries",
  allowCreate: false,
  allowEdit: false,
  allowDelete: true,
  showRowActions: true,
  showSearch: false,
  authorizedRoles: {
    entity: ["super_admin"],
  },
  titleField: "name",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: "Name",
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
      usedToShow: true,
    },
    {
      identifier: "email",
      label: "Email",
      accessor: "email",
      inList: true,
      type: "email",
      required: true,
    },
    {
      identifier: "phone_number",
      label: "Phone Number",
      accessor: "phone_number",
      inList: true,
      type: "phone_number",
      required: true,
    },
    {
      identifier: "message",
      label: "Message",
      accessor: "message",
      inList: false,
      type: "text",
      required: true,
    },
  ],
});
