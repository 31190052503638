const dashboardConfig = {
  groups: [
    {
      endpoint: "admin/dashboard/dashboard_data",
      components: [
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: "Total number of users",
              },
              body: {
                accessor: "users_count",
              },
            },
          },
        },
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: "Total number of coaches",
              },
              body: {
                accessor: "coaches_count",
              },
            },
          },
        },
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: "Active users in current program",
              },
              body: {
                accessor: "current_program_active_users",
              },
            },
          },
        },
      ],
    },
  ],
};

export default dashboardConfig;