import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "article",
  pluralName: "articles",
  pathName: "articles",
  endpoint: "/admin/articles",
  serverName: "article",
  serverPluralName: "articles",
  displayName: "Articles",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  useFormData: true,
  customErrorKeys: ["article_categories","body_images"],
  authorizedRoles: {
    entity: ["super_admin"],
    create: ["super_admin"],
    edit: ["super_admin"],
    delete: ["super_admin"],
  },
  titleField: "title",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: false,
      usedToShow: false,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "title",
      label: "Title",
      accessor: "title",
      inList: true,
      type: "string",
      required: true,
      usedToShow: true,
    },
    {
      identifier: "text",
      label: "Text",
      accessor: "text",
      inList: false,
      type: "editor",
      editorConfig: {
      imageUploadType: "external",
      endpoint: "admin/images",
    },
      required: true,
    },
    {
      identifier: "cover_photo",
      label: "Cover Photo",
      accessor: "cover_photo",
      type: "image",
      required: true,
    },
    {
      identifier: "short_description",
      label: "Short Description",
      accessor: "short_description",
      inList: false,
      type: "text",
      required: true,
    },
    {
      identifier: "slug",
      label: "Slug",
      accessor: "slug",
      inList: false,
      hideInCreate: true,
      hideInEdit: true,
      type: "string",
    },
    {
      identifier: "article_categories_attributes",
      label: "Article Categories",
      accessor: "article_categories_attributes",
      hideInShow: true,
      type: "transferList",
      transferListConfig: {
        accessor: "article_categories",
      },
      optionsEntity: {
        name: "category",
        labelField: "name",
        valueField: "id",
        valueLabel: "category_id",
        filterParams: [
          {
            name: "article_id",
            value: ["params", "id"],
            type: "edit",
          },
        ],

        required: true,
      },
    },
    {
      identifier: "meta_title",
      label: "Meta Title",
      accessor: "meta_title",
      inList: false,
      type: "string",
    },
    {
      identifier: "meta_description",
      label: "Meta Description",
      accessor: "meta_description",
      inList: false,
      type: "text",
    },
    {
      identifier: "meta_image",
      label: "Meta Image",
      accessor: "meta_image",
      type: "image",
    },
  ],
});
