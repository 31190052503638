import { Entity } from "@thewebops/admin-panel";
import moment from "moment";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";

export default new Entity({
  name: "habit",
  pluralName: "habits",
  pathName: "habits",
  endpoint: "/admin/habits",
  serverName: "habit",
  serverPluralName: "habits",
  displayName: "Habits",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  showSearch: true,
  customErrorKeys: ["question","body_images"],
  authorizedRoles: {
    entity: ["super_admin", "editor", "observer"],
    create: ["super_admin", "editor"],
    edit: ["super_admin", "editor"],
    delete: ["super_admin", "editor"],
  },
  titleField: "name",
  rowActions: [
    {
      label: "Export",
      name: "exportHabit",
    },
  ],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "last_editor_name",
      label: "Last Editor Name",
      accessor: "last_editor_name",
      inList: false,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "updated_at",
      label: "Last Updated At:",
      accessor: (dataObject) =>
        dataObject.updated_at ? moment(dataObject.updated_at).format("dddd, MMMM Do YYYY, h:mm a") : null,
      inList: false,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "program_id",
      label: "Program",
      formAccessor: (dataObject) => (dataObject.program ? dataObject.program.id : null),
      accessor: (dataObject) => (dataObject.program ? dataObject.program.name : null),
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "program",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "name",
      label: "Name",
      accessor: "name",
      inList: true,
      usedToShow: true,
      type: "string",
      required: true,
    },
    {
      identifier: "due_date",
      label: "Due Date",
      accessor: "due_date",
      inList: true,
      type: "date",
      required: true,
    },
    {
      identifier: "body",
      label: "Content",
      accessor: "body",
      inList: false,
      type: "editor",
      editorConfig: {
        imageUploadType: "external",
        endpoint: "admin/images",
      },
      required: true,
    },
    {
      identifier: "non_interactive",
      label: "Interactive / Noninteractive",
      formAccessor: (dataObject) => (dataObject.non_interactive ? 1 : 0),
      accessor: (dataObject) => (dataObject.non_interactive ? "Non Interactive" : "Interactive"),
      type: "select",
      required: false,
      inList: true,
      defaultValue: 0,
      options: [
        { label: "Interactive", value: 0 },
        { label: "Not Interactive", value: 1 },
      ],
    },
    {
      identifier: "question_attributes",
      accessor: "question",
      label: "Question & Answer",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "id",
          label: "ID",
          accessor: "id",
          hideInCreate: true,
          hideInEdit: true,
          hideInShow: true,
        },
        {
          identifier: "question_type",
          label: "Question Type",
          accessor: "question_type",
          type: "select",
          required: true,
          options: [
            { value: "yes_or_no", label: "yes or no question" },
            { value: "text", label: "text question" },
          ],
        },
        {
          identifier: "question_body",
          label: "Question Body",
          accessor: "question_body",
          type: "text",
          required: true,
        },
      ],
    },
  ],
  filters: [
    {
      label: "Program",
      name: "program_id",
      type: "select",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "dynamic",
        defaultValue: "first",

        // optionsEntity is required if optionsType is dynamic
        optionsEntity: {
          name: "program",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: "Start Date",
      name: "start_date",
      type: "date",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "static",
      },
    },
    {
      label: "End Date",
      name: "end_date",
      type: "date",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "static",
      },
    },
  ],
});
