import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import moment from "moment";
import { Entity } from "@thewebops/admin-panel";
import UserTraffic from "components/UserTraffic/";

export default new Entity({
  name: "user",
  pluralName: "users",
  pathName: "users",
  endpoint: "/admin/users",
  serverName: "user",
  serverPluralName: "users",
  displayName: "Users",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  authorizedRoles: {
    entity: ["super_admin", "observer"],
    create: ["super_admin"],
    edit: ["super_admin"],
    delete: ["super_admin"],
  },
  export: {
    enabled: true,
    extension: "xls",
    fileName: "users",
  },
  rowActions: [
    {
      label: "Deactivate",
      name: "lockUser",
      active: (dataObject) => (dataObject.locked ? false : true),
    },
    {
      label: "Activate",
      name: "unlockUser",
      active: (dataObject) => (dataObject.locked ? true : false),
    },
    {
      label: "Send orientation message",
      name: "sendMessage",
      active: (dataObject) => !dataObject.received_orientation_messages,
    },
  ],
  titleField: "name",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: false,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "email",
      label: "Email",
      accessor: "email",
      inList: true,
      type: "email",
      required: true,
      usedToShow: true,
    },
    {
      identifier: "name",
      label: "Name",
      accessor: "name",
      inList: true,
      usedToShow: true,
      type: "string",
      required: true,
    },
    {
      identifier: "last_active_at",
      hideInCreate: true,
      hideInEdit: true,
      label: "Activity",
      accessor: "last_active_at",
      inList: true,
      type: "string",
      component: UserTraffic,
    },
    {
      identifier: "created_at",
      hideInCreate: true,
      hideInEdit: true,
      label: "Signed up on",
      inList: true,
      type: "string",
      accessor: (dataObject) =>
        dataObject.created_at ? moment(dataObject.created_at).format("Do MMMM YYYY, h:mm a") : null,
    },
    {
      identifier: "payment_created_at",
      hideInCreate: true,
      hideInEdit: true,
      label: "Payment time",
      inList: true,
      type: "string",
      accessor: (dataObject) =>
        dataObject.payment_created_at ? moment(dataObject.payment_created_at).format("Do MMMM YYYY, h:mm a") : null,
    },
    {
      identifier: "promo_code",
      accessor: "promo_code",
      hideInCreate: true,
      hideInEdit: true,
      label: "Promocode",
      inList: true,
      type: "string",
    },
    {
      identifier: "age",
      hideInCreate: true,
      label: "Age",
      accessor: "age",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "phone_number",
      hideInCreate: true,
      label: "Phone number",
      accessor: "phone_number",
      inList: false,
      type: "string",
      required: true,
    },

    {
      identifier: "initial_assesment_completed",
      label: "Initial Assessment",
      accessor: (dataObject) => (dataObject.initial_assesment_completed ? "True" : "False"),
      hideInCreate: true,
      hideInEdit: true,
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "program_id",
      label: "Program",
      formAccessor: (dataObject) => (dataObject.program ? dataObject.program.id : null),
      accessor: (dataObject) => (dataObject.program ? dataObject.program.name : null),
      type: "reference",
      inList: true,
      required: true,
      optionsEntity: {
        name: "program",
        labelField: "name",
        valueField: "id",
      },
      hideInEdit: true,
    },
    {
      identifier: "payment_type",
      label: "Payment Type",
      accessor: "payment_type",
      inList: true,
      type: "select",
      required: true,
      options: [
        { value: "pre_paid", label: "Prepaid" },
        { value: "regular", label: "Regular" },
      ],
    },
    {
      identifier: "payment_method",
      label: "Payment Method",
      accessor: (dataObject) => (dataObject.payment_method ? dataObject.payment_method : "-"),
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      type: "string",
    },
  ],
  filters: [
    {
      label: "Program",
      name: "program_id",
      type: "select",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        defaultValue: "first",
        optionsType: "dynamic",

        // optionsEntity is required if optionsType is dynamic
        optionsEntity: {
          name: "program",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: "Activity Status",
      name: "activity_status",
      type: "select",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { label: "Active", value: "active" },
          { label: "Partially Active", value: "partially_active" },
          { label: "Not Active", value: "not_active" },
        ],
      },
    },
    {
      label: "Payment",
      name: "payment",
      type: "select",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { label: "Paid", value: "paid" },
          { label: "All", value: "all" },
          { label: "Unpaid", value: "unpaid" },
        ],
      },
    },
  ],
});
