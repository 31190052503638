import admin from "./Admin";
import coach from "./Coach";
import program from "./Program";
import user from "./User";
import inquiry from "./Inquiry";
import answer from "./Answer";
import checkupDate from "./CheckupDate";
import nutibite from "./Nutibite";
import checkup from "./Checkup";
import initialAssesment from "./InitialAssesment";
import exercise from "./Exercise";
import habit from "./Habit";
import savedReplyCategory from "./SavedReplyCategory";
import savedReply from "./SavedReply";
import promoCode from "./PromoCode";
import article from "./Article";
import category from "./Category";
import faq from "./Faq";
import testimonial from "./Testimonial";
import pageSetting from "./PageSetting";
import exportDocument from "./ExportDocument";

const userDefinedEntities = {
  admin,
  coach,
  program,
  user,
  inquiry,
  answer,
  checkupDate,
  nutibite,
  checkup,
  initialAssesment,
  exercise,
  habit,
  savedReplyCategory,
  savedReply,
  promoCode,
  article,
  category,
  faq,
  testimonial,
  pageSetting,
  exportDocument,
};

const groups = [
  {
    name: "Program Content",
    type: "normal",
    entities: [nutibite, exercise, habit],
  },
  {
    name: "ungrouped",
    entities: [
      user,
      admin,
      program,
      coach,
      inquiry,
      answer,
      checkupDate,
      checkup,
      initialAssesment,
      savedReplyCategory,
      savedReply,
      promoCode,
      article,
      faq,
      category,
      testimonial,
      pageSetting,
      exportDocument,
    ],
  },
];
export { userDefinedEntities, groups };
