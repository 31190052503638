import ConversationHeader from "components/ConversationHeader";
import UserInfo from "components/UserInfo";
import React from "react";
import { CABLE_BASE_URL } from "utils/constants";

const findCoachUserAttribute = (participations, attribute) => {
  const coachIndex = participations.findIndex((partipation) => partipation.participant_type === "coach");
  const userIndex = participations.findIndex((partipation) => partipation.participant_type === "user");
  const coachAttribute = coachIndex > -1 && participations[coachIndex].participant[attribute];
  const userAttribute = userIndex > -1 && participations[userIndex].participant[attribute];

  return { coachAttribute, userAttribute };
};

const addAbsolutePathToImage = (imageUrl) => {
  if (!imageUrl) return null;
  return imageUrl.startsWith("http") ? imageUrl : `//${imageUrl}`;
};

const getParticpantId = (conversation) => {
  const id = conversation?.participations?.find((element) => element.participant_type === "user")?.participant?.id;
  return id;
};

const chatConfigs = {
  enabled: true,
  isObserver: true,
  authorizedRoles: ["super_admin", "observer"],
  chatOpsWeb: {
    conversationName: (conversation) => {
      const { participations } = conversation;
      if (!participations) return "Chat";
      const { coachAttribute: coachName, userAttribute: userName } = findCoachUserAttribute(participations, "name");
      if (coachName && userName) return `${userName} • ${coachName}`;
      return coachName || userName || "Chat";
    },
    conversationNameInChat: (conversation) => <ConversationHeader conversation={conversation} />,
    resolveConversationTargetImage: (conversation) => {
      const { participations } = conversation;
      if (!participations) return null;
      const { userAttribute: userImage } = findCoachUserAttribute(participations, "image_url");
      return addAbsolutePathToImage(userImage);
    },
    getIdFromConversation: (conversation) => getParticpantId(conversation),
    UserInfoModalContent: UserInfo,
    isBroadcastEnabled: false,
    theme: {
      primaryColor: "rgb(0, 46, 58)",
      background: "https://res.cloudinary.com/thewebops/image/upload/v1600629686/background_jvg2z3.jpg",
    },
    orientationPriority: "User",
  },
  chatopsActionCable: {
    apiEndpoints: {
      fetchMessages: {
        endpoint: (id) => {
          return `/conversations/${id}`;
        },
        method: "GET",
      },
      fetchContact: {
        endpoint: (id) => `users/${id}/info`,
        method: "get",
      },
    },
    conversationSearchParamName: "username",
  },
  senderType: "Admin",
  nameSpace: "admin",
  cableURL: CABLE_BASE_URL,
  filters: [
    {
      entity: "coach",
      label: "Coach",
      name: "coach_id",
      type: "select",
      hideTypography: true,
      placeholder: "Coach",
      selectConfig: {
        isSearchable: false,
        optionsType: "dynamic",
        optionsEntity: {
          name: "coach",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
};

export default chatConfigs;
