import React from "react";
import moment from "moment";

import { TrafficColor } from "./styled";

const UserTraffic = ({ value, width, height }) => {
  let userTraffic = "#ff0000";
  let valueToHours = (value) => {
    return moment().diff(moment(value), "hours");
  };

  if (valueToHours(value) < 72 && valueToHours(value) >= 24) {
    userTraffic = "#ffff00";
  } else if (valueToHours(value) < 24) {
    userTraffic = "#00ff00";
  }

  return <TrafficColor traffic={userTraffic} width={width} height={height} />;
};

export default UserTraffic;
