import styled from "styled-components";

export const UserTrafficWrapper = styled.span`
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConversationHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ParticipantWrapper = styled.span`
  margin-left: 0.2vw;
`;
