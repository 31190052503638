import { Entity } from "@thewebops/admin-panel";

export default (authConfig) =>
  new Entity({
    title: "Activate",
    subTitle: `Enter the email associated with your ${authConfig.displayName} account, a password reset email will be sent to you!`,
    path: "/unlockUser",
    submitButtonText: "Request Lock User",
    action: "create",
    fields: [
      {
        label: "Email",
        required: true,
        type: "email",
        identifier: "email",
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverName}/unlock`,
      method: "patch",
      successMessage: "Email has been sent",
      errorMessage: "Couldn't send email",
    },
  });
