import { Entity } from "@thewebops/admin-panel";
import moment from "moment";

export default new Entity({
  name: "exportDocument",
  pluralName: "exportDocuments",
  pathName: "export_documents",
  endpoint: "/admin/export_documents",
  serverName: "export_document",
  serverPluralName: "export_documents",
  displayName: "Export Documents",
  allowDelete: true,
  showRowActions: true,
  requestParams: {
    list: {
      includes: 'exportable',
    },
  },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
    },
    {
      identifier: "status",
      label: "Status",
      accessor: "status",
      type: "string",
      inList: true,
    },
    {
      identifier: "created_at",
      label: "Created At",
      accessor: dataObject => moment(dataObject.created_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "string",
    },
    {
      identifier: "updated_at",
      label: "Updated At",
      accessor: dataObject => moment(dataObject.updated_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "string",
    },
    {
      identifier: "exportable_type",
      label: "Document Type",
      accessor: "exportable_type",
      inList: true,
      type: "string",
    },
    {
      identifier: "exportable_name",
      label: "Document name",
      accessor: (dataObject) => (dataObject?.exportable?.name),
      inList: true,
      type: "string",
    },
    {
      identifier: "file",
      label: "Download link",
      accessor: "file_url",
      type: "file",
      inList: true,
      hideInShow: true,
    },
  ],
  filters: [
    {
      label: "Document Type",
      name: "exportable_type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { label: "Habit", value: "Habit" },
          { label: "Nutibite", value: "Lesson" },
          { label: "Exercise", value: "Exercise" },
        ],
      },
    },
  ],
});
