import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "category",
  pluralName: "categories",
  pathName: "categories",
  endpoint: "/admin/categories",
  serverName: "category",
  serverPluralName: "categories",
  displayName: "Blog Categories",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  authorizedRoles: {
    entity: ["super_admin"],
    create: ["super_admin"],
    edit: ["super_admin"],
    delete: ["super_admin"],
  },
  titleField: "name",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: "Name",
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
  ],
});
