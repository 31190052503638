import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "admin",
  pluralName: "admins",
  pathName: "admins",
  endpoint: "/admin/admins",
  serverName: "admin",
  serverPluralName: "admins",
  displayName: { en: "Admins", ar: "المدراء" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  authorizedRoles: {
    entity: ["super_admin"],
  },
  rowActions: [
    {
      label: { en: "Reset Password", ar: "Reset bel 3araby" },
      name: "sendForgetPassword",
      authorizedRoles: ["super_admin"],
    },
  ],
  titleField: "name",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "Name bel 3araby" },
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "email",
      label: "Email",
      accessor: "email",
      inList: true,
      type: "email",
      hideInEdit: true,
      required: true,
    },
    {
      identifier: "role",
      label: "Role",
      accessor: "role",
      inList: true,
      type: "select",
      options: [
        { label: "Editor", value: "editor" },
        { label: "Observer", value: "observer" },
        { label: "Super Admin", value: "super_admin" },
      ],
      required: true,
    },
    {
      identifier: "confirmed_at",
      label: "Confirmed ?",
      accessor: (dataObject) => (dataObject.confirmed_at ? "Yes" : "No"),
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
    },
  ],
});
