import { createAction } from "redux-starter-kit";

const actions = {
  lockCoach: {
    request: createAction("admin/lockCoach/request"),
    success: createAction("admin/lockCoach/success"),
    failure: createAction("admin/lockCoach/failure"),
  },
  unlockCoach: {
    request: createAction("admin/unlockCoach/request"),
    success: createAction("admin/unlockCoach/success"),
    failure: createAction("admin/unlockCoach/failure"),
  },
};

export default actions;
