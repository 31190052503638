import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import UserCount from "./components/UserCount";
import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "coach",
  pluralName: "coaches",
  pathName: "coaches",
  endpoint: "/admin/coaches",
  serverName: "coach",
  serverPluralName: "coaches",
  displayName: "Coaches",
  allowCreate: true,
  allowEdit: true,
  allowDelete: false,
  showRowActions: true,
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  showSearch: false,
  customErrorKeys: ["only_one_master", "image"],
  authorizedRoles: {
    entity: ["super_admin", "observer"],
    list: ["super_admin"],
    create: ["super_admin"],
    edit: ["super_admin"],
    delete: ["super_admin"],
  },
  rowActions: [
    {
      label: "Deactivate",
      name: "lockCoach",
      active: (dataObject) => (dataObject.locked ? false : true),
    },
    {
      label: "Activate",
      name: "unlockCoach",
      active: (dataObject) => (dataObject.locked ? true : false),
    },
  ],
  titleField: "email",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "email",
      label: "Email",
      accessor: "email",
      inList: true,
      type: "email",
      required: true,
      hideInEdit: true,
    },
    {
      identifier: "role",
      label: "Role",
      accessor: "role",
      inList: true,
      type: "select",
      options: [
        { label: "Normal", value: "normal" },
        { label: "Master", value: "master" },
      ],
      required: true,
    },
    {
      identifier: "user_count",
      label: "User Count",
      accessor: "coach_users",
      hideInCreate: true,
      type: "string",
      component: UserCount,
      formComponent: UserCount,
    },
    {
      identifier: "coach_users_attributes",
      label: "Users",
      accessor: "coach_users",
      type: "reference",
      multiple: true,
      isSearchable: true,
      useObjectArray: true,
      optionsEntity: {
        name: "user",
        labelField: "name",
        valueField: "id",
        valueLabel: "user_id",
        filterParams: [
          {
            name: "coach_id",
            value: ["params", "id"],
            type: "edit",
          },
          {
            name: "active_programs_only",
            value: true,
            type: "both",
          },
          {
            name: "payment",
            value: "paid",
            type: "both",
          },
          {
            name: "not_assigned_to_any_coach",
            value: true,
            type: "both",
          },
        ],
      },
    },
  ],
  filters: [
    {
      label: "Coach",
      name: "coach_id",
      type: "select",
      authorizedRoles: [],
      selectConfig: {
        isSearchable: true,
        optionsType: "dynamic",
        optionsEntity: {
          name: "coach",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
});
