import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "checkupDate",
  pluralName: "checkupDates",
  pathName: "checkup_dates",
  endpoint: "/admin/checkup_dates",
  serverName: "checkup_date",
  displayName: "Checkup Dates",
  serverPluralName: "checkup_dates",
  allowCreate: true,
  allowEdit: false,
  allowDelete: true,
  showRowActions: true,
  showSearch: false,
  authorizedRoles: {
    entity: ["super_admin"],
  },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "program_id",
      label: "Program",
      accessor: (dataObject) => (dataObject.program ? dataObject.program.name : null),
      formAccessor: (dataObject) => (dataObject.program ? dataObject.program.id : null),
      inList: true,
      usedToShow: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "program",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "due_date",
      label: "Due date",
      accessor: "due_date",
      inList: true,
      type: "date",
      required: true,
    },
    {
      identifier: "initial",
      label: "Initial?",
      accessor: "initial",
      inList: true,
      type: "select",
      required: true,
      options: [
        { value: true, label: "True" },
        { value: false, label: "False" },
      ],
    },
  ],
  filters: [
    {
      label: "Program",
      name: "program_id",
      type: "select",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "dynamic",
        defaultValue: "first",

        // optionsEntity is required if optionsType is dynamic
        optionsEntity: {
          name: "program",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
});
