const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENV || "development") {
    case "development":
      return "http://localhost:3000/v1";
    case "stg-dev":
      return "https://dev-nutivate-api.thewebops.com/v1";
    case "staging":
      return "https://nutivate-api.thewebops.com/v1";
    case "production":
      return "https://api.nutivate.com/v1";
    default:
      return "https://dev-nutivate-api.thewebops.com/v1";
  }
};

export const CABLE_BASE_URL = `${getBaseUrl().slice(0, -3)}/chat_engine/cable`;

export const API_BASE_URL = getBaseUrl();
