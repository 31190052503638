import actions from "./actions";
import { userDefinedEntities } from "../.";

const reducers = {
  [actions.exportNutibite.request]: (state, action) => {
    state.loading = true;
  },
  [actions.exportNutibite.success]: (state, action) => {
    state.loading = false;
    const objIndex = state.data.findIndex(obj => obj.id === action.payload.id);
    state.data[objIndex] = action.payload;
  },
  [actions.exportNutibite.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
};

export default reducers;
