import { createAction } from "redux-starter-kit";

const actions = {
  exportNutibite: {
    request: createAction("admin/exportNutibite/request"),
    success: createAction("admin/exportNutibite/success"),
    failure: createAction("admin/exportNutibite/failure"),
  },
};

export default actions;
