import React from "react";
import {
  UserInfoContainer,
  UserInfoCard,
  UserBasicInfo,
  UserImage,
  UserBasicInfoDetails,
  UserProgress,
  ProgressItem,
  CardTitle,
  CardDetails,
  CardDetailsItem,
} from "./styled";
import avatar from "../../assets/avatar.png";
import UserTraffic from "components/UserTraffic";
import { Progress } from "antd";

const assesmentInformation = (assesment) => [
  { title: "Job", key: "work_do_for_living" },
  { title: "Marital Status", key: "marital_status" },
  { title: "Children", key: "children_age" },
  { title: "Pregnant", key: "has_children" },
  { title: "Breast Feeding", key: "breast_feed" },
  { title: "Medical History", key: "health_conditions" },
  { title: "Medication", key: "work_do_for_living" },
  {
    title: `Patient is ${!assesment?.has_children ? "not" : ""} pregnant`,
    dependsOn: "gender",
    dependsOnValue: "Female",
  },
  {
    title: `Patient is ${!assesment?.breast_feed ? "not" : ""} breast feeding`,
    dependsOn: "gender",
    dependsOnValue: "Female",
  },
  { title: "Injuries", key: "injuries" },
  { title: "Allergies", key: "food_allergies" },
  { title: "Sensitivity", key: "food_sensitive" },
  { title: "Alcohol drink number", key: "drinks_no" },
  { title: "Travel plans", key: "travel_plans" },
  { title: `I have ${!assesment?.has_sleep_difficulty ? "no" : ""} sleep issues` },
  { title: "Meal struggled the most", key: "meals_struggle_explaination" },
  { title: "Previous diet attempts", key: "diet_attempts" },
  { title: "Second priority goal", key: "second_priority_goal" },
  { title: "Type of progress", key: "important_progress_explaination" },
  { title: "Frequent complaint", key: "most_frequent_complains" },
  { title: "Other Info", key: "other_information" },
  { title: "Weight", key: "weight" },
  { title: "Height", key: "height" },
  { title: "Body Fat %", key: "body_fat_percentage" },
];

const progressInformation = [
  { title: "Overall", percent: "consistencyPercentage" },
  { title: "Nutibites", percent: "lessonPercentage" },
  { title: "Habits", percent: "habitsPercentage" },
  { title: "Exercise", percent: "exercisePercentage" },
];

const checkupInformation = [
  {
    title: "Latest weight measured:",
    key: "weight",
    trailingInfo: "KG",
  },
  {
    title: "Latest waist measured:",
    key: "waist_circumference",
    trailingInfo: "cm",
  },
  {
    title: "Latest hips measured:",
    key: "hip_circumference",
    trailingInfo: "cm",
  },
  {
    title: "Latest body fat measured:",
    key: "body_fat_percentage",
    trailingInfo: "%",
    dependsOn: "body_fat_percentage",
  },
];

const UserInfo = ({ data }) => {
  if (!data) return <UserInfoContainer>Loading...</UserInfoContainer>;

  const { initial_assesment: assesment, progress, last_checkup: checkup, name, age, image, last_active_at } = data;

  const { gender } = assesment || {};
  const {
    habit_progress: { no_of_answered_habits: answeredHabits, no_of_habits: habits },
    exercise_progress: { no_of_answered_exercises: answeredExercises, no_of_exercises: exercises },
    lesson_progress: { no_of_answered_lessons: answeredLessons, no_of_lessons: lessons },
  } = progress;

  const calculateValue = (value1, value2) => {
    const value = value1 / value2;
    return Number.isNaN(value) ? 0 : value;
  };

  const habitsPercentage = parseFloat(calculateValue(answeredHabits, habits) * 100).toFixed(1);
  const exercisePercentage = parseFloat(calculateValue(answeredExercises, exercises) * 100).toFixed(1);
  const lessonPercentage = parseFloat(calculateValue(answeredLessons, lessons) * 100).toFixed(1);
  const consistencyPercentage = parseFloat(
    lessonPercentage * 0.1 + habitsPercentage * 0.6 + exercisePercentage * 0.3,
  ).toFixed(1);

  const percentages = {
    habitsPercentage,
    exercisePercentage,
    lessonPercentage,
    consistencyPercentage,
  };

  const renderProgress = () =>
    progressInformation.map((element) => (
      <ProgressItem>
        <p>{element.title}</p>
        <Progress trailColor="#c4c4c4" strokeColor="#0b4a47" percent={percentages?.[element.percent]} />
      </ProgressItem>
    ));

  const renderCheckupInformation = () =>
    checkupInformation.map((element) =>
      element.dependsOn && !checkup?.[element.dependsOn] ? null : (
        <CardDetailsItem>
          <strong>{element.title}</strong> {checkup?.[element.key]} {element.trailingInfo}
        </CardDetailsItem>
      ),
    );

  const renderCheckup = () =>
    checkup ? (
      <UserInfoCard>
        <CardTitle>Recent Updates: (Submitted At: {checkup.submitted_at})</CardTitle>
        <CardDetails>{renderCheckupInformation()}</CardDetails>
      </UserInfoCard>
    ) : (
      <UserInfoCard>
        <CardTitle>Check up not filled yet</CardTitle>
      </UserInfoCard>
    );

  const renderIntialInformation = (assesment) => {
    return assesmentInformation(assesment).map((element) => {
      return element.dependsOn && assesment?.[element.dependsOn] !== element.dependsOnValue ? null : (
        <UserDetail title={element.title} value={element.key ? assesment?.[element.key] : undefined} />
      );
    });
  };

  const renderAssessment = (assesment) => (
    <UserInfoCard>
      <CardTitle>Initial Information:</CardTitle>
      <CardDetails>{renderIntialInformation(assesment)}</CardDetails>
    </UserInfoCard>
  );

  const addAbsolutePathToImage = () => {
    if (!image) return null;
    return image.startsWith("http") ? image : `//${image}`;
  };

  const renderUserInfo = () => (
    <UserInfoCard>
      <UserBasicInfo>
        <UserImage image={addAbsolutePathToImage() || avatar} />
        <UserBasicInfoDetails>
          <p>{name}</p>
          {gender && <p>{gender}</p>}
          <UserTraffic value={last_active_at} />
          <p>{age} YO</p>
        </UserBasicInfoDetails>
      </UserBasicInfo>
      <UserProgress>{renderProgress()}</UserProgress>
    </UserInfoCard>
  );

  return (
    <div>
      {assesment || data ? (
        <UserInfoContainer>
          {renderUserInfo()}
          {renderCheckup()}
          {assesment && renderAssessment(assesment)}
        </UserInfoContainer>
      ) : (
        <h1>User has not filled Initial Assesment</h1>
      )}
    </div>
  );
};

const UserDetail = ({ title, value }) => (
  <CardDetailsItem>
    <strong>{title}</strong>
    {value ? ":" : ""} {value}
  </CardDetailsItem>
);

export default UserInfo;
