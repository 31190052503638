import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "program",
  pluralName: "programs",
  pathName: "programs",
  endpoint: "/admin/programs",
  serverName: "program",
  serverPluralName: "programs",
  displayName: "Programs",
  allowCreate: true,
  allowEdit: true,
  allowDelete: false,
  showRowActions: true,
  showSearch: false,
  customErrorKeys: ["program", "master_coach", "orientation_messages"],
  authorizedRoles: {
    entity: ["super_admin"],
    list: ["super_admin", "editor", "observer"],
  },
  titleField: "name",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: "Name",
      accessor: "name",
      inList: true,
      usedToShow: true,
      type: "string",
      required: true,
    },
    {
      identifier: "start_date",
      label: "Start Date",
      accessor: "start_date",
      inList: true,
      type: "date",
      required: true,
    },
    {
      identifier: "end_date",
      label: "End Date",
      accessor: "end_date",
      inList: true,
      type: "date",
      required: true,
    },
    {
      identifier: "price_usd",
      label: "Price in USD",
      accessor: "price_usd",
      inList: true,
      type: "number",
      required: true,
    },
    {
      identifier: "price_egp",
      label: "Price in EGP",
      accessor: "price_egp",
      inList: true,
      type: "number",
      required: true,
    },
    {
      identifier: "master_coach_id",
      label: "Master Coach",
      accessor: (dataObject) => (dataObject.master_coach ? dataObject.master_coach.name : null),
      formAccessor: (dataObject) => (dataObject.master_coach ? dataObject.master_coach.id : null),
      type: "reference",
      optionsEntity: {
        name: "coach",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "orientation_messages_attributes",
      label: "Orientation messages",
      accessor: "orientation_messages",
      inList: false,
      type: "dynamic",
      fields: [
        {
          identifier: "id",
          label: "ID",
          accessor: "id",
          hideInCreate: true,
          hideInEdit: true,
          hideInShow: true,
        },
        {
          identifier: "message",
          label: "Message",
          accessor: "message",
          type: "text",
          required: true,
        },
        {
          identifier: "index",
          label: "Index",
          accessor: "index",
          defaultValue: 1,
          type: "number",
          required: true,
        },
      ],
    },
    {
      identifier: "source_program_id",
      label: "Source Program",
      hideInEdit: true,
      inList: false,
      hideInShow: true,
      accessor: (dataObject) => (dataObject.source_program ? dataObject.source_program.name : null),
      formAccessor: (dataObject) => (dataObject.source_program ? dataObject.source_program.id : null),
      type: "reference",
      optionsEntity: {
        name: "program",
        labelField: "name",
        valueField: "id",
      },
    },
  ],
});
