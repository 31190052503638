import { createAction } from "redux-starter-kit";

const actions = {
  exportExercise: {
    request: createAction("admin/exportExercise/request"),
    success: createAction("admin/exportExercise/success"),
    failure: createAction("admin/exportExercise/failure"),
  },
};

export default actions;
