export const customTheme = {
  colors: {
    background: "rgb(0, 46, 58)",
    main: "#fff",
  },
  sidebar: {
    fixed: true,
    theme: "dark",
    customTheme: {
      background: "rgb(0, 46, 58)",
      textColor: "##fff",
      subMenuColor: "rgb(0, 46, 58)",
    },
  },
  header: {
    logoWidth: "80px",
  },
};
