import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "faq",
  pluralName: "faqs",
  pathName: "faqs",
  endpoint: "/admin/faqs",
  serverName: "faq",
  serverPluralName: "faqs",
  displayName: "FAQs",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  authorizedRoles: {
    entity: ["super_admin"],
    create: ["super_admin"],
    edit: ["super_admin"],
    delete: ["super_admin"],
  },
  customErrorKeys: ["body_images"],
  titleField: "FAQ",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "question",
      label: "Question",
      accessor: "question",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "answer",
      label: "Answer",
      accessor: "answer",
      inList: false,
      type: "editor",
      editorConfig: {
        imageUploadType: "external",
        endpoint: "admin/images",
      },
      required: true,
    },
  ],
});
