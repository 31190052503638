import styled from "styled-components";

const breakpoints = {
  lg: "1199px",
  md: "991px",
  sm: "767px",
  xs: "556px",
};

export const UserInfoContainer = styled.div`
  padding: 20px;
  width: 50vw;
  max-height: 90vh;
  @media only screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`;

export const UserInfoCard = styled.div`
  width: 100%;
  border-radius: 20px;
  background-color: white;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -1px 15px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

export const UserBasicInfo = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`;

export const UserImage = styled.div`
  background: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  min-width: 50%;
  padding-bottom: 50%;
  border-radius: 50%;
`;

export const UserBasicInfoDetails = styled.div`
  margin-left: 18px;
  p {
    color: #4c4c4c;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
  }
  p:nth-child(1) {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 20px;
    text-decoration: underline;
    margin-bottom: 18px;
  }
`;

export const UserProgress = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
    margin-top: 15px;
  }
`;

export const ProgressItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin: 8px 0;
  p {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
  }
  .ant-progress-line {
    width: 60%;
    margin: 0 5px;
  }
`;

export const CardTitle = styled.p`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  color: #4c4c4c;
  margin: 0;
  width: 100%;
  text-align: left;
`;

export const CardDetails = styled.div`
  margin-left: 20px;
  width: 100%;
`;

export const CardDetailsItem = styled.div`
  margin: 10px 0;
  font-family: "" Roboto ", " Helvetica ", " Arial ", sans-serif", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  color: #4c4c4c;
  span {
    margin-left: 10px;
    font-weight: 300;
  }
`;
