import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router/immutable";
import { AuthReducersCreator } from "@thewebops/admin-panel";
import { authConfig } from "authEntities";

const rootReducer = (history, adminPanelReducers) =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducersCreator(authConfig),
    ...adminPanelReducers,
  });

export default rootReducer;
