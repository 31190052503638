import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "testimonial",
  pluralName: "testimonials",
  pathName: "testimonials",
  endpoint: "/admin/testimonials",
  serverName: "testimonial",
  serverPluralName: "testimonials",
  displayName: "Testimonials",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  authorizedRoles: {
    entity: ["super_admin"],
    create: ["super_admin"],
    edit: ["super_admin"],
    delete: ["super_admin"],
  },
  customErrorKeys: ["body_images"],
  titleField: "name",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "client",
      label: "Client Name",
      accessor: "client",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "testimonial",
      label: "Testimonial",
      accessor: "testimonial",
      inList: false,
      type: "editor",
      editorConfig: {
        imageUploadType: "external",
        endpoint: "admin/images",
      },
      required: true,
    },
  ],
});
