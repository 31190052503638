import { takeLatest, call, put } from "redux-saga/effects";
import i18n from "i18next";
import { showNotification } from "@thewebops/admin-panel";
import { request } from "@thewebops/admin-panel";
import actions from "./actions";

function* sendForgetPasswordInstructions(action) {
  const { item } = action.payload;
  const { success, failure } = actions.sendForgetPassword;
  try {
    yield call(request, {
      url: `admin/passwords`,
      method: "post",
      authenticated: true,
      data: {
        email: item.email,
      },
    });
    yield put(success());
    showNotification(null, "success", i18n.t("notifications:passwordReset"));
  } catch (error) {
    yield put(failure());
    showNotification(null, "error");
  }
}

function* watchEntitySendForgetPasswordInstructions() {
  const { request } = actions.sendForgetPassword;
  yield takeLatest(request, sendForgetPasswordInstructions);
}

export default [watchEntitySendForgetPasswordInstructions];
