import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "promoCode",
  pluralName: "promoCodes",
  pathName: "promo_codes",
  endpoint: "/admin/promo_codes",
  serverName: "promo_code",
  serverPluralName: "promo_codes",
  displayName: "Promo Codes",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  authorizedRoles: {
    entity: ["super_admin"],
    create: ["super_admin"],
    edit: ["super_admin"],
    delete: ["super_admin"],
  },
  titleField: "name",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "program_id",
      label: "Program",
      formAccessor: (dataObject) => (dataObject.program ? dataObject.program.id : null),
      accessor: (dataObject) => (dataObject.program ? dataObject.program.name : null),
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "program",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "name",
      label: "Promo code name",
      accessor: "name",
      type: "string",
      inList: true,
    },
    {
      identifier: "code",
      label: "Code",
      accessor: "code",
      type: "string",
      inList: true,
    },
    {
      identifier: "start_date",
      label: "Start date",
      accessor: "start_date",
      type: "date",
      inList: true,
    },
    {
      identifier: "end_date",
      label: "End date",
      accessor: "end_date",
      type: "date",
      inList: true,
    },
    {
      identifier: "discounted_price_usd",
      label: "Discounted Price in USD",
      accessor: "discounted_price_usd",
      type: "number",
      inList: true,
    },
    {
      identifier: "discounted_price_egp",
      label: "Discounted Price in EGP",
      accessor: "discounted_price_egp",
      type: "number",
      inList: true,
    },
  ],
  filters: [
    {
      label: "Program",
      name: "program_id",
      type: "select",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "dynamic",
        defaultValue: "first",

        // optionsEntity is required if optionsType is dynamic
        optionsEntity: {
          name: "program",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
});
