import login from "./login";
import forgetPassword from "./forgetPassword";
import resetPassword from "./resetPassword";
import completeProfile from "./completeProfile";
import activate from "./activate";
import deactivate from "./deactivate";
import resendConfirmation from "./resendConfirmation";

const authConfig = {
  name: "admin",
  pluralName: "admins",
  pathName: "admins",
  serverName: "admin",
  serverPluralName: "admins",
  displayName: "Admin",
};

const authUserDefinedEntities = [
  login,
  forgetPassword,
  resetPassword,
  completeProfile,
  activate,
  deactivate,
  resendConfirmation,
];

export { authConfig, authUserDefinedEntities };
