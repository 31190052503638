import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "pageSetting",
  pluralName: "page_settings",
  pathName: "page_settings",
  endpoint: "/admin/page_settings",
  serverName: "page_setting",
  serverPluralName: "page_settings",
  displayName: "Page Settings",
  allowCreate: false,
  allowEdit: true,
  allowDelete: false,
  showRowActions: true,
  showSearch: true,
  useFormData: true,
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "key",
      label: "Key",
      accessor: "key",
      type: "string",
      inList: true,
      disabled: true,
      hideInCreate: false,
      hideInEdit: false,
      required: true,
    },
    {
      identifier: "group",
      label: "Group",
      accessor: "group",
      inList: true,
      hideInCreate: false,
      hideInEdit: false,
      disabled: true,
      required: true,
      type: "select",
      options: [
        {
          value: "general",
          label: "General",
        },
        {
          value: "home_page",
          label: "Homepage",
        },
      ],
    },
    {
      identifier: "value_type",
      label: "Value",
      accessor: "value_type",
      type: "select",
      dependee: true,
      inList: true,
      hideInCreate: false,
      hideInEdit: false,
      disabled: true,
      required: true,
      options: [
        {
          value: "text",
          label: "Text",
        },
        {
          value: "image",
          label: "Image",
        },
        {
          value: "attachment",
          label: "Attachment",
        },
      ],
    },
    {
      identifier: "attachment",
      accessor: "attachment",
      label: "Attachment",
      type: "file",
      dependsOn: "value_type",
      dependsOnValue: "attachment",
      inList: false,
      hideInCreate: false,
      hideInEdit: false,
      required: true,
    },
    {
      identifier: "value",
      accessor: "value",
      label: "value",
      type: "text",
      dependsOn: "value_type",
      dependsOnValue: "text",
      inList: false,
      hideInCreate: false,
      hideInEdit: false,
      required: true,
    },
    {
      identifier: "value",
      accessor: "value",
      label: "value",
      type: "editor",
      dependsOn: "value_type",
      dependsOnValue: "editor",
      inList: false,
      hideInCreate: false,
      hideInEdit: false,
      required: true,
    },
  ],
});
