import actions from "./actions";
import { userDefinedEntities } from "../.";

const reducers = {
  [actions.lockCoach.request]: (state, action) => {
    state.loading = true;
  },
  [actions.lockCoach.success]: (state, action) => {
    state.loading = false;
    const index = state.data.findIndex((el) =>
      userDefinedEntities["coach"].compareIdentifierValue(el, action.payload.id),
    );
    state.data[index] = action.payload;
    state.data[index].locked = true;
  },
  [actions.lockCoach.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
  [actions.unlockCoach.request]: (state, action) => {
    state.loading = true;
  },
  [actions.unlockCoach.success]: (state, action) => {
    state.loading = false;
    const index = state.data.findIndex((el) =>
      userDefinedEntities["coach"].compareIdentifierValue(el, action.payload.id),
    );
    state.data[index] = action.payload;
    state.data[index].locked = false;
  },
  [actions.unlockCoach.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
};

export default reducers;
