import { Entity } from "@thewebops/admin-panel";
import UserTraffic from "components/UserTraffic/";

export default new Entity({
  name: "answer",
  pluralName: "answers",
  pathName: "answers",
  endpoint: "/admin/answers",
  serverName: "answer",
  serverPluralName: "answers",
  displayName: "Answers",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  showSearch: true,
  export: {
    enabled: true,
    extension: "xls",
    fileName: "answers",
  },
  authorizedRoles: {
    entity: ["super_admin"],
  },
  titleField: "user",
  fields: [
    {
      identifier: "last_active_at",
      hideInCreate: true,
      hideInEdit: true,
      label: "Activity",
      accessor: (dataObject) => (dataObject.user ? dataObject.user.last_active_at : null),
      inList: true,
      type: "string",
      component: UserTraffic,
    },
    {
      identifier: "user",
      label: "User name",
      accessor: (dataObject) => (dataObject.user ? dataObject.user.name : null),
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "question",
      label: "Question",
      accessor: (dataObject) => (dataObject.question ? dataObject.question.question_body : null),
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "answer",
      label: "Answer",
      accessor: "answer_body",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "type",
      label: "Type",
      accessor: (dataObject) => (dataObject.question ? dataObject.question.questionable_type : null),
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "name",
      label: "Lesson/Habit/Exercise Name",
      accessor: (dataObject) => (dataObject.lesson_habit_exercise ? dataObject.lesson_habit_exercise.name : null),
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "due_date",
      label: "Lesson/Habit/Exercise Due Date",
      accessor: (dataObject) => (dataObject.lesson_habit_exercise ? dataObject.lesson_habit_exercise.due_date : null),
      inList: true,
      type: "string",
      required: true,
    },
  ],
  filters: [
    {
      label: "Program",
      name: "program_id",
      type: "select",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "dynamic",
        defaultValue: "first",

        // optionsEntity is required if optionsType is dynamic
        optionsEntity: {
          name: "program",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: "Type",
      name: "questionable_type",
      type: "select",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "static",

        // optionsEntity is required if optionsType is dynamic
        optionsArray: [
          { value: "", label: "All" },
          { value: "Lesson", label: "Lesson" },
          { value: "Habit", label: "Habit" },
          { value: "Exercise", label: "Exercise" },
        ],
      },
    },
    {
      label: "Start Date",
      name: "start_date",
      type: "date",
    },
    {
      label: "End Date",
      name: "end_date",
      type: "date",
    },
  ],
});
