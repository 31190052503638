import actions from "./actions";
import { userDefinedEntities } from "../.";

const reducers = {
  [actions.lockUser.request]: (state, action) => {
    state.loading = true;
  },
  [actions.lockUser.success]: (state, action) => {
    state.loading = false;
    const index = state.data.findIndex((el) =>
      userDefinedEntities["user"].compareIdentifierValue(el, action.payload.id),
    );
    state.data[index] = action.payload;
    state.data[index].locked = true;
  },
  [actions.lockUser.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
  [actions.unlockUser.request]: (state, action) => {
    state.loading = true;
  },
  [actions.unlockUser.success]: (state, action) => {
    state.loading = false;
    const index = state.data.findIndex((el) =>
      userDefinedEntities["user"].compareIdentifierValue(el, action.payload.id),
    );
    state.data[index] = action.payload;
    state.data[index].locked = false;
  },
  [actions.unlockUser.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
  [actions.sendMessage.request]: (state, action) => {
    state.loading = true;
  },
  [actions.sendMessage.success]: (state, action) => {
    state.loading = false;
    const index = state.data.findIndex((el) =>
      userDefinedEntities["user"].compareIdentifierValue(el, action.payload.id),
    );
    state.data[index] = action.payload;
    state.data[index].received_orientation_messages = true;
  },
  [actions.sendMessage.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
};

export default reducers;
