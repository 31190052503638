import React from "react";
import UserTraffic from "../UserTraffic";
import { ConversationHeaderWrapper, ParticipantWrapper, UserTrafficWrapper } from "./styled";

const ConversationHeader = ({ conversation }) => {
  const getUserParticipant = () => {
    return conversation?.participations?.find((element) => element.participant_type === "user");
  };
  const getCoachParticipant = () => {
    return conversation?.participations?.find((element) => element.participant_type === "coach");
  };
  return (
    <ConversationHeaderWrapper>
      <span>{getUserParticipant().participant.name} </span>
      <ParticipantWrapper>•</ParticipantWrapper>
      <ParticipantWrapper>{getCoachParticipant().participant.name}</ParticipantWrapper>

      <UserTrafficWrapper>
        <UserTraffic
          width="10px"
          height="10px"
          value={getUserParticipant()?.participant.last_active_at}
        />
      </UserTrafficWrapper>
    </ConversationHeaderWrapper>
  );
};

export default ConversationHeader;
