import { createAction } from "redux-starter-kit";

const actions = {
  sendForgetPassword: {
    request: createAction("admin/sendForgetPassword/request"),
    success: createAction("admin/sendForgetPassword/success"),
    failure: createAction("admin/sendForgetPassword/failure"),
  },
};

export default actions;
